<template>
  <div class="publishPage bgfff">
    <div class="app h5MaxWidth">
      <header class="">
        <div class="header h5MaxWidth clear display alignCenter">
          <div
            class="back display alignCenter justifyCenter"
            onclick="window.history.go(-1)"
          >
            <i class="iconfont icon-arrow-left"></i>
          </div>
          <h2 class="flex1">Publish</h2>
          <div class="navskip" id="sideMenu"></div>
        </div>
      </header>

      <div class="main">
        <div class="publish forms">
          <form id="publish" action="#" method="post" onsubmit="GO">
            <ul>
              <li>
                <div class="formTitle">
                  <h4>Utterance</h4>
                </div>
                <div class="inputArea display alignStart">
                  <div class="leftSideBox display alignCenter">
                    <i class="i_inputIcon iconfont icon-marker"></i>
                  </div>
                  <textarea
                    name="utterance"
                    id="utterance"
                    v-model="remark"
                    placeholder="Utterance"
                    class="utterance fillArea flex1"
                  ></textarea>
                </div>
              </li>
              <li>
                <div class="formTitle">
                  <h4>Picture upload</h4>
                </div>
                <div>
                  <van-uploader
                    v-model="fileList"
                    multiple
                    @delete="deleteData"
                    :after-read="afterRead"
                  />
                </div>
              </li>
            </ul>
            <div class="actionBar display alignCenter alignStretch justifyEnd">
              <div
                class="publishBtn clickBtn display alignCenter justifyCenter"
                @click="publishQuery()"
              >
                <p>Publish</p>
                <i class="iconfont icon-arrow-right-3"></i>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
      uploadList: [],
      remark: "",
    };
  },
  components: {},
  methods: {
    deleteData(file, detail) {
      console.log(file, detail);
      const index = detail.index;
      this.uploadList.splice(index, 1);
    },
    afterRead(file) {
      this.getAjax(
        "api/files/image",
        {
          file: file.file,
        },
        "post"
      ).then((res) => {
        if (res.code === 200) {
          this.uploadList.push(res.url);
        } else {
          this.tips(res.msg, "danger");
        }
      });
    },
    publishQuery() {
      console.log(this.fileList);
      this.getAjax(
        "api/income/submit",
        {
          uid: this.getItem("uid"),
          thumbs: this.uploadList.join(","),
          remark: this.remark,
        },
        "post"
      ).then((res) => {
        if (res.code === 200) {
          this.tips("successs");
        } else {
          this.tips(res.msg, "warning");
        }
      });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.skeleton .sketch {
  margin: 10px 0 0;
}

.userCard .portrait .vip_ICON {
  width: 20px;
  height: 20px;
  right: -3px;
  bottom: -2px;
  position: absolute;
}

.forumPage .main {
  top: -50px;
  position: relative;
}

.forumPage .openRule .title p {
  font-weight: 700;
  font-size: 16px;
}

.forumPage .tips {
  background-color: transparent;
}

.forumPage .list {
  min-height: 80px;
  margin: 5px;
}

.forumPage .list ul {
  min-height: 20px;
}

.forumPage .list ul > li {
  margin: 0 0 10px;
  padding: 20px;
  background-color: #fff;
}

.forumPage .list ul > li > div {
  border-width: 0;
  border-color: var(--black3);
  border-style: dashed;
}

.forumPage .list ul > li:last-child > div {
  border-width: 0;
}

.forumPage .list ul > li .commentContent {
  margin: 10px 0 0;
  padding: 10px 0 0;
  border-radius: 0 15px 15px 15px;
  -webkit-border-radius: 0 0 15px 15px 15px;
  -moz-border-radius: 0 0 15px 15px 15px;
}

.forumPage .list ul > li .utterance p {
  font-size: 18px;
  color: var(--black1);
}

.forumPage .list ul > li .photoGroup {
  margin: 20px 0 0;
}

.forumPage .list ul > li .photoGroup > div {
  max-width: 100%;
  min-width: 120px;
  max-height: 360px;
  padding: 10px;
  overflow: hidden;
  background-color: var(--a_5);
}

.forumPage .list ul > li .photoGroup > div:not(:last-child) {
  margin: 0 10px 0 0;
}

.forumPage .list ul > li .photoGroup > div img {
  width: 100%;
  display: block;
}

.forumPage .list ul > li .photoGroup > div.callOut {
  max-width: initial;
  min-width: initial;
  max-height: initial;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 5px;
  overflow: scroll;
  position: fixed;
  background-color: transparent;
  z-index: 9999;
}

.forumPage .list ul > li .photoGroup > div.callOut:after {
  content: " ";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.3);
  position: fixed;
  display: block;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  -ms-backdrop-filter: blur(2px);
  z-index: 0;
}

.forumPage .list ul > li .photoGroup > div.callOut img {
  margin: 0 0 100px;
  display: block;
  position: relative;
  z-index: 1;
}

.forumPage .list ul > li .reward .icon {
  width: 35px;
}

.forumPage .list ul > li .reward .icon img {
  width: 100%;
}

.forumPage .list ul > li .reward p {
  line-height: 1;
  font-size: 28px;
  font-weight: 700;
  color: var(--b_1);
}

.forumPage .list ul > li .reward span {
  color: var(--black3);
  font-size: 12px;
  font-weight: 300;
  display: block;
}

.forumPage .list ul > li .pubdate p {
  text-align: right;
  font-size: 16px;
  color: var(--black2);
}

.forumPage .list ul > li .statusBar {
  margin: 10px 0 0;
  padding: 10px 0 0;
  background-color: #fff;
  position: relative;
  border-width: 5px 0 0;
  border-color: var(--black4);
  border-style: dotted;
}

.publishEnter {
  top: 110px;
  right: -2px;
  padding: 5px 10px;
  position: fixed;
  background-color: var(--a_1);
  border-radius: 500px 0 0 500px;
  -webkit-border-radius: 500px 0 0 500px;
  -moz-border-radius: 500px 0 0 500px;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  z-index: 99;
}

.publishEnter .icon {
  width: 60px;
  /* height: 60px; */
  overflow: hidden;
}

.publishEnter .icon i {
  font-size: 36px;
  color: #fff;
}

.publishEnter p {
  line-height: 1;
  font-size: 14px;
  color: #fff;
  text-align: center;
}

.publishPage .publish {
  margin: 10px;
  padding: 20px;
  background-color: #fff;
}

.publishPage .utterance {
  max-height: 350px;
}

.publishBtn {
  background-color: var(--b_1);
}

.sketchBox .addPicture {
  width: 80px;
  height: 80px;
  position: relative;
  background-color: var(--black5);
}

.sketchBox .addPicture i {
  font-size: 26px;
  color: var(--black3);
}

.sketchBox .addPicture > input {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 0.0000001;
  z-index: 9;
}

.sketchBox .sketchList > div {
  height: 80px;
  margin: 0 10px 10px 0;
  position: relative;
}

.sketchBox .sketchList > div img {
  height: 100%;
}

.sketchBox .sketchList .imgDelete {
  width: 25px;
  height: 25px;
  top: -5px;
  right: -5px;
  position: absolute;
  z-index: 9;
  background-color: var(--r_3);
}

.sketchBox .sketchList .imgDelete i {
  font-size: 14px;
  color: #fff;
}

#previewSketch .promptMain {
  width: 100%;
  max-height: 100vh;
  top: 0;
  background-color: transparent;
}

#previewSketch .promptMain .roundOff {
  background-color: var(--r_3);
}

#previewSketch .promptMain .promptCont {
  padding: 0;
  max-height: 100vh;
}

#previewSketch .promptCont img {
  width: 100%;
}

.publishQuery {
  margin: 0 0 10px;
  padding: 0 0 10px;
}
</style>
